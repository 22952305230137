<script>
  export default {
    name: 'google-analytics',
    created() {
      CONST.script.googleAnalytics && this.initGoogleAnalytics();
    },
    methods: {
      initGoogleAnalytics() {
        /*
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');


        function check_ga() {
          if (typeof ga === 'function') {
            console.log('Loaded :'+ window.ga);
            window.ga('create', 'UA-115740172-1', 'auto');
            window.ga('require', 'indGaPlugin', { apikey: 'b1742e22-c744-4203-97a8-8be1886e544f' });
            setTimeout(window.ga('send', 'pageview'),1000);
          } else {
            console.log('Not loaded');
            setTimeout(check_ga,100);
          }
        }
        check_ga();
        
        
        setTimeout(window.ga('require', 'indGaPlugin', { apikey: 'b1742e22-c744-4203-97a8-8be1886e544f' }),0);*/
        //const url = 'https://www.google-analytics.com/analytics.js?id=UA-115740172-1';
       /* const url = 'https://www.googletagmanager.com/gtag/js?id=UA-115740172-1';

        let script = document.createElement('script')
        script.setAttribute('src', url)
        script.setAttribute('async', true)
        script.setAttribute('defer', true)
        document.head.appendChild(script)

        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-115740172-1');
        function check_ga() {
          if (typeof window.ga === 'function') {
            console.log('Loaded :'+ window.ga);
          } else {
            console.log('Not loaded');
            setTimeout(check_ga,100);
          }
        }
        check_ga();
        
        global.Analytic.send('Widget Loaded');
*/
        const url = 'https://www.googletagmanager.com/gtag/js?id=G-5V8CKG583T';

        let script = document.createElement('script')
        script.setAttribute('src', url)
        script.setAttribute('async', true)
        script.setAttribute('defer', true)
        document.head.appendChild(script)

        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-5V8CKG583T');
        //global.Analytic.send('Widget Loaded');

        let countUA = 0;
        function check_ga() {
          if (typeof window.ga === 'function' && typeof window.mixpanel === 'object') {
            console.debug('Loaded UA and Mixpanel');
            global.Analytic.send('Widget Loaded');
          } else {
            countUA++;
            if(countUA > 10){
              return;
            }
            console.debug('Not loaded');
            setTimeout(check_ga, 100);
          }
        }
        check_ga();

      }
    }
  };
</script>
