import Vue from 'vue';

import '@/core/init/setting.js';   // global unit
import '@/core/init/const.js';   // global unit
import store from '@/core/init/store';

import '@/core/init/unit.js';   // global unit
import '@/core/init/moment.js'; // global moment
import '@/core/init/share.js'; // global share

import App from '@/core/init/layout.vue';
import router from '@/core/router';

// disable service worker
// import '@/core/init/registerServiceWorker';

import '@/core/init/mapLeafletFix.js'; // global map
import '@/core/init/mapLeafletJs.js'; // global map

import vuetify from '@/plugins/vuetify' // path to vuetify export


import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents);

import Notifications from 'vue-notification'
// Use Notify
Vue.use(Notifications)

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  // ...,                // ...other defaults
});

// X3
Vue.config.productionTip = false;


// localization
let i18next;

if (CONST.language.localTranslate) {
  i18next = require('@/core/lang/local').default;
} else {
  i18next = require('@/core/lang/locize').default;
}
//

import VueI18Next from '@panter/vue-i18next'

Vue.use(VueI18Next);
let i18n = new VueI18Next(i18next);

// Directives
import vClickOutside from 'v-click-outside';
// https://github.com/ndelvalle/v-click-outside
Vue.use(vClickOutside);

import attachDirectives from '@/function/global/directivesInit'
attachDirectives(Vue)

// Add tooltip
// https://github.com/Akryum/v-tooltip
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)


// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: 'UA-115740172-1',
//   checkDuplicatedScript: true,
//   debug: {
//     // enabled: true, // default value
//     // trace: true, // default value
//     // sendHitTask: true // default value
//   }
// })

// https://stackoverflow.com/questions/3420004/access-parent-url-from-iframe
let domain = (window.location != window.parent.location)
  ? document.referrer
  : document.location.href;
function getHost(url) {
  var a = document.createElement('a');
  a.href = url;
  return a.hostname;
}
domain = getHost(domain);

global.Analytic = (function(){

  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}

  var sendGtag = (name, data = {}) => {

    let event_label = name;
    let event_action = 'Interaction';
    switch (name) {
      case 'Widget Loaded':
        event_action = 'Open';
        break;
      case 'Map Hotel Open Link booking':
        event_action = 'Redirect Booking';
        break;
      case 'Packages Open Link':
        event_action = 'Redirect';
        break;
      case 'Map Hotel Open Link partner':
      case 'Map Hotel Open Link ratehawk':
      case 'Map POI Open Link partner':
      case 'Service Visa':
      case 'Service RentCar':
      case 'Service Insurance':
      case 'Service Transfer':
        event_action = 'Redirect Other';
        break;
      case 'Outbound':
        event_action = 'Outbound';
        event_label = data && data.url;
        break;
      case 'Outbound poster board':
        event_action = 'Outbound poster board';
        event_label = data && data.url;
        break;
      case 'Popup view':
        break;
    }
    
    let current_url = new URL(document.location.href);

    if(current_url.pathname == '/offer/poi'){
      const ev = JSON.parse(current_url.searchParams.get('event'))
      domain = ev && ev.name;
    }
    if (!domain) return
    let gtagData = {
      event_action    : event_action,
      event_label     : event_label,
      event_category  : domain, // домен
      event_value     : data || {}
    }
    console.debug('[ANALYTIC] ', gtagData);
    gtag('event', name, gtagData);
    if (typeof window.ga === 'function') {
      let gaData = {
        eventAction    : event_action,
        eventLabel     : event_label,
        eventCategory  : domain
      }
      console.debug('send to UA');
      window.ga('send', 'event', gaData);
    }
    if (typeof window.mixpanel === 'object') {
      window.mixpanel.track(
        domain,
        {
          'action': event_action,
          'label' : event_label,
          'url'   : data && data.url || ''
        }
      );
    }
    let ind_data = data || {};
    window.Indicative && window.Indicative.buildEvent(event_label, ind_data);
  };

  return {
    // send: messageAdd
    send: sendGtag
  }
})();

// Регистрируем глобальную пользовательскую директиву `v-focus`
Vue.directive('log', {
  //
  // bind: вызывается однократно, при первичном связывании директивы с элементом. Здесь можно поместить код инициализации.
  //
  // inserted: вызывается после вставки связанного элемента внутрь элемента родителя (заметьте, что сам родитель может на этот момент и не принадлежать ещё основному дереву элементов).
  inserted: function (el, binding, vnode) {
    // console.log(el, binding, vnode)
    switch (true) {
      case binding.modifiers.click:
        el.addEventListener('click', ()=>{
          // gtag('event', binding.value.name, binding.value)
          Analytic.send( binding.value.name, binding.value.data);
          // VueApp.$ga.event('categoryTest', 'click', binding.value.name, binding.value)
          }, false
        );
        break;
    }
    // var s = JSON.stringify
    // let test =
    //   'name: '       + s(binding.name) + '<br>' +
    //   'value: '      + s(binding.value) + '<br>' +
    //   'expression: ' + s(binding.expression) + '<br>' +
    //   'argument: '   + s(binding.arg) + '<br>' +
    //   'modifiers: '  + s(binding.modifiers) + '<br>' +
    //   'vnode keys: ' + Object.keys(vnode).join(', ')
  }
})



global.Vue = Vue;
global.VueApp = new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');

