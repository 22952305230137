import Event from "@/class/Event";

export default (request) => {

  return new Promise(async (resolve) => {
    let event = new Event();

    request.name && event.setName(request.name);

    request.dateStart && event.setDateStart(moment(request.dateStart));
    request.dateEnd   && event.setDateEnd(moment(request.dateEnd));

    //
    request.venue &&  request.venue.address  && event.setVenueAddress(request.venue.address);

    request.hotels    && event.setHotels(request.hotels);
    request.poi       && event.setPoi(request.poi);
    request.icon      && event.setIcon(request.icon);

    // TODO
    if(!(request.venue &&  request.venue.lat) || !(request.venue &&  request.venue.lon)){
      if(request.venue &&  request.venue.address){

        const getInfoByAddress = (address) => {
          return new Promise((resolve) => {

            mapInit.then(() => {
              let geocoder = new google.maps.Geocoder();
              geocoder.geocode( { 'address': address}, function(results, status) {
                if (status === 'OK') {
                  resolve(results[0].geometry.location)
                } else {
                  console.debug('Geocode was not successful for the following reason: ' + status);
                  resolve(null)
                }
              });
            });

          });
        };

        let location = await getInfoByAddress(request.venue.address);
        // console.log(location);

        if(location){
          request.venue.lat = location.lat();
          request.venue.lon = location.lng();
        }
      }
    }

    if( (request.venue &&  request.venue.lat) && (request.venue &&  request.venue.lon)){

      request.venue.lat  && event.setVenueLat(request.venue.lat);
      request.venue.lon  && event.setVenueLon(request.venue.lon);

    } else {
      console.debug('EventLoadByData - not coordinates')
    }

    resolve(event);
  });

}

