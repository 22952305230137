<template>
  <!--    class="projectGrid-[xs|md|lg] projectName-[ilss|sf] projectType-[widget|default] projectStyle-[default|red|blue]"-->
  <div id="app" :class="[getClass, navMenuClass, navLogoOnly, mobileClass]">
    <RegionContent/>
    <v-app style="height: 0">
    </v-app>
    <notifications :position="!isMobile ? 'top center' : 'top left'" />
  </div>
</template>

<script>
  import intercom         from '@/core/layout/intercom';
  import grid             from '@/core/layout/grid';
  import yandexMetrika    from '@/core/layout/yandexMetrika';
  import googleAnalytics  from '@/core/layout/googleAnalytics';
  import {isMobile} from 'mobile-device-detect';

  export default {
    name: 'layout-default',
    mixins: [
      grid,
      intercom,
      yandexMetrika,
      googleAnalytics
    ],
    components: {
      RegionContent : require('@/core/layout/region/content.vue').default,
    },
    data: function () {
      return {
        modeClassName: 'user',
        isMobile
      };
    },
    computed:{
      getClass(){
        return this.getGridClass + ' ' + 'mode-' + this.modeClassName;
      },
      navMenuClass(){
        let mode = 'default';

        if (this.widgetSettings && this.widgetSettings.navMenuMod) {
          mode = this.widgetSettings.navMenuMod;
        }
        return 'offer-nav-fix-' + mode;
      },
      navLogoOnly() {
        const S = this.widgetSettings;
        if (S.navMenuMod !== 'disable' && S.navMenu.length === 1 && S.navMenu[0].type === 'logo') {
          return 'offer-nav-fix-logo-only';
        }
        return '';
      },
      mobileClass() {
        return isMobile ? 'mobile' : ''
      },
      widgetSettings() {
        return this.$store.getters.widgetSettings;
      }
    },
    created() {
      let secret = 'iwqwerty';
      let lenSuccess = 0;
      document.addEventListener(`keydown`, event => {

        if(lenSuccess >= secret.length ){return}

        if (event.which == secret[lenSuccess].charCodeAt(0) || event.key == secret[lenSuccess]) {
          // event.preventDefault();
          lenSuccess++
        } else {
          lenSuccess = 0

          if (event.which == secret[lenSuccess].charCodeAt(0) || event.key == secret[lenSuccess]) {
            lenSuccess++
          }
        }

        if(lenSuccess >= secret.length ){
          this.modeClassName = 'manager';
        }
      }, false);
    },
  };
</script>

<style lang="scss">
// Это идет в app.css (глобальные стили)
@import '~@style/init.scss';

//
.mode-manager-only { display: none !important;}
.mode-manager .mode-manager-only{
  display: block !important;
}

.mobile .vue-notification-group {
  width: 100% !important;

  .vue-notification-wrapper .vue-notification-template {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.vue-notification-group {
  top: 50px !important;
}
</style>
