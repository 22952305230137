<script>
  export default {
    name: 'intercom',
    created() {
      if(this.widgetSettings && this.widgetSettings.disableIntercom){
        return;
      }
      console.debug('init intercom');
      CONST.script.intercom && this.initIntercom();
    },
    methods: {
      initIntercom() {
        window.intercomSettings = {
          app_id: "xja0gm6o",
        };
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/xja0gm6o';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

        window.Intercom('onShow', () => {
          Analytic.send('Intercom Open')
        });
      }
    }
  };
</script>
<style>
  iframe.intercom-launcher-frame,
  iframe[name="intercom-launcher-frame"] {
    bottom: 30px !important;
    right: 10px !important;
  }
  .fb_dialog_advanced {
    bottom: 180pt !important;
    z-index: 500 !important;
  }
  .intercom-messenger-frame {
    bottom: 100pt !important;
  }

</style>
