// routerEventWidgetInit
export default (route) => {

  let event       = route.query.event  ? JSON.parse(route.query.event)              : {};
  let eventHotels = route.query.eventHotels  ? JSON.parse(route.query.eventHotels)  : [];
  let eventPoi    = route.query.eventPoi     ? JSON.parse(route.query.eventPoi)     : [];


  let eventLoadType = null;
  let eventRequest  = null;
  let type         = event.type || ''; // route.query.routeInit // TODO: fix event.type - не может показывать каким образом получать данные - use routeInit - подумать
  switch (type) {
    case 'eventById':
      eventLoadType = 'eventById';
      eventRequest = Object.assign({}, event, {hotels: eventHotels, poi: eventPoi});
      break;
    case 'eventByData':
      eventLoadType = 'eventByData';
      eventRequest = Object.assign({}, event, {hotels: eventHotels, poi: eventPoi});
      break;

    // test case
    case 'test-eventById':
      eventLoadType = 'eventById';
      eventRequest =  {
        id: "73429",
        icon: "https://partners..com/wp-content/uploads/2020/03/SanFrancisco-marathon.png",
        //
        hotels: [
          {
            "name": "Hilton San Francisco Financial District",
            "url": "https://www.hilton.com/en/hi/groups/personalized/S/SFOFDHF-JUMP-20200724/index.jhtml?WT.mc_id=POG",
            "price": "219",
            "currency": "USD",
            "lat": 37.7952284,
            "lng": -122.4040729
          },
          {
            "name": "Hyatt Regency San Francisco",
            "url": "https://www.hyatt.com/en-US/group-booking/SFORS/G-SF20",
            "price": "299",
            "currency": "USD",
            "lat": 37.7942389,
            "lng": -122.3957083
          },
          {
            "name": "Hotel Vitale",
            "url": ".synxis.com/rez.aspx?hotel=59783&chain=25042&template=RBE&shell=RBE2&arrive=7/23/2020&depart=07/27/2020&promo=MARATHON",
            "price": "297",
            "currency": "USD",
            "lat": 37.7936121,
            "lng": -122.3931164
          }
        ],
        //
        poi:[
          {
            "place": "2 Marina Blvd",
            "type": "tshirt",
            "url": "https://www.thesfmarathon.com/race-weekend/the-expo/",
            "lat": 37.8053418,
            "lng": -122.4324908,
            "description": "CAPITAL ONE EXPO"
          },
          {
            "place": "14th Ave East Meadow",
            "type": "general",
            "url": "https://www.thesfmarathon.com/the-races/2nd-half-marathon/",
            "lat": 37.7710651,
            "lng": -122.4723894,
            "description": "2nd Half Marathon Start"
          },
          {
            "place": "San Francisco International Airport",
            "type": "airport",
            "url": "https://www.flysfo.com/",
            "lat": 37.6213129,
            "lng": -122.3789554,
            "description": "SFO"
          },
          {
            "place": "Oakland International Airport",
            "type": "airport",
            "url": "https://www.oaklandairport.com/",
            "lat": 37.7125689,
            "lng": -122.2197428,
            "description": "OAK"
          }
        ]
      };
      break;
    case 'test-eventByData':
      eventLoadType = 'eventByData';
      eventRequest = {
        "name": "Event example",
        "venue": {
          "address": "Bremen",
          "lat": 53.07929619999999,
          "lon": 8.8016936,
          "city": {
            "name": "Bremen"
          }
        }
      };
      break;

    // TODO: fix
    default:
      eventLoadType = 'eventByData';
      eventRequest = {
        "name": "Event example",
        "venue": {
          "address": "Bremen",
          "lat": 53.07929619999999,
          "lon": 8.8016936,
          "city": {
            "name": "Bremen"
          }
        }
      };
      break;
  }
  if(eventRequest){
    // eslint-disable-next-line no-undef
    VueStore.dispatch('eventWidget_load', {
      loadType  : eventLoadType,
      request   : eventRequest,
    })
  }

}

